import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import EmailVerify from "./pages/EmailVerify";
import EmailVerifyLawyer from "./pages/EmailVerifyLawyer";
import UserForgetPass from "./pages/UserForgetPass";
import UserResetPass from "./pages/UserResetPass";
import LawyerForgetPass from "./pages/LawyerForgetPass";
import LawyerResetPass from "./pages/LawyerResetPass";
import LawyerDetail from "./pages/LawyerDetail";
import Payment from "./components/payment/PaymentMain";
import UserDashboard from "./pages/UserDashboard";
import HesapDoğrulamaEkranı from "./components/şifre yenileme/HesapDoğrulamaEkrani.jsx";
import LawyerDashboard from "./pages/LawyerDashboard";
import LawyerHomePage from "./pages/LawyerHomePage";
import AdminDashboard from "./pages/AdminDashboard";
import FaqMain from "./components/frequently asking questions/FaqMain.jsx";
import SifreSıfırlama from "./pages/ResetPassword";
import YeniSifreBelirle from "./components/şifre yenileme/YeniSifreBelirle";
import UyeliginizAktifEdildi from "./pages/UyeliginizAktifEdildi";
import Header from "./components/Header";
import RequestPage from "./components/RequestPage";
import Teams from "./pages/Teams.jsx";
import UserPastPay from "./components/UserDashboard/UserPastPay.jsx";
import ProtectedRoute from "./ProtectedRoute";
import AllLawyers from "./components/AdminDashboard/Lawyers.jsx";
import LawyerDetailsPopup from "./components/AdminDashboard/components/LawyerDetailPage.jsx";
import LawyerDetailsPage from "./components/AdminDashboard/components/LawyerDetailPage.jsx";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/search"
        element={
          <ProtectedRoute allowedRoles={["user", "lawyer"]}>
            <SearchPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lawyer/:lawyerid"
        element={
          <ProtectedRoute allowedRoles={["user"]}>
            <LawyerDetail />
          </ProtectedRoute>
        }
      />
      <Route path="/verify/:id/:token" element={<EmailVerify />} />
      <Route path="/lawyer/verify/:id/:token" element={<EmailVerifyLawyer />} />
      <Route path="/forget-password" element={<UserForgetPass />} />
      <Route path="/sifre-sıfırlama" element={<SifreSıfırlama />} />
      <Route path="/hesap-doğrulama" element={<HesapDoğrulamaEkranı />} />
      <Route path="/reset-password/:id/:token" element={<UserResetPass />} />
      <Route path="/forget-password-law" element={<LawyerForgetPass />} />
      <Route path="/yeni-sifre-belirle" element={<YeniSifreBelirle />} />
      <Route
        path="/userDashboard"
        element={
          <ProtectedRoute allowedRoles={["user"]}>
            <UserDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userpastpay"
        element={
          <ProtectedRoute allowedRoles={["user"]}>
            <UserPastPay />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lawyerDashboard"
        element={
          <ProtectedRoute allowedRoles={["lawyer"]}>
            <LawyerDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lawyerhomepage"
        element={
          <ProtectedRoute allowedRoles={["lawyer"]}>
            <LawyerHomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/adminDashboard"
        element={
          <ProtectedRoute allowedRoles={["admin"]}>
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/frequentlyaskingquestion"
        element={
          <ProtectedRoute allowedRoles={["user", "lawyer"]}>
            <FaqMain />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <ProtectedRoute allowedRoles={["user", "lawyer"]}>
            <Payment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/üyeliğiniz-aktif-edildi"
        element={<UyeliginizAktifEdildi />}
      />
      <Route
        path="/reset-password-law/:id/:token"
        element={<LawyerResetPass />}
      />
      <Route path="/header" element={<Header />} />
      <Route
        path="/request/:status"
        element={
          <ProtectedRoute allowedRoles={["user", "lawyer"]}>
            <RequestPage />
          </ProtectedRoute>
        }
      />
      <Route path="/ekibimiz" element={<Teams />} />
      <Route path="/" element={<AllLawyers />} />

      <Route path="/lawyer-details/:id" element={<LawyerDetailsPage />} />
    </Routes>
  );
};

export default AppRoute;
