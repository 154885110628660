import React, { useContext, useState } from "react";
import LawyerRegister from "../pages/LawyerRegister";
import UserRegister from "../pages/UserRegister";
import UserLogin from "../pages/UserLogin";
import LawyerLogin from "../pages/LawyerLogin";
import { Dropdown, Navbar as NB, Container, Nav } from "react-bootstrap";
import { Store } from "../Store";
import { toast } from "react-toastify";
import ButtonMainColor from "./Buttons/ButtonMainColor";
import ButtonWhiteColor from "./Buttons/ButtonWhiteColor";
import { useNavigate } from "react-router-dom";
import "../css/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  const [showLawyerRegister, setShowLawyerRegister] = useState(false);
  const [showLawyerLogin, setShowLawyerLogin] = useState(false);
  const [showUserRegister, setShowUserRegister] = useState(false);
  const [showUserLogin, setShowUserLogin] = useState(false);
  const { state, dispatch, setIsLawyer } = useContext(Store);
  const { userInfo, lawyerInfo, is_lawyer } = state;
  const navigate = useNavigate();

  const userSignOut = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    toast.success("Çıkış Yaptınız.");
    navigate("/");
  };

  const lawyerSignOut = () => {
    dispatch({ type: "LAWYER_SIGNOUT" });
    localStorage.removeItem("lawyerInfo");
    toast.success("Çıkış Yaptınız.");
    setIsLawyer(false);
    navigate("/");
  };

  const user = () => {
    setIsLawyer(false);
  };

  const dropdownToggleStyle = {
    backgroundColor: "#B4B8A1",
    color: "var(--main-color)",
    border: "none",
    fontSize: "15px",
    fontWeight: "bold",
  };

  return (
    <>
      <NB
        className="navbar-top"
        expand="lg"
        variant="light"
        style={{ margin: 0 }}
      >
        <Container className="mobileres">
          <NB.Brand onClick={() => navigate("/")}>
            {/* <img src={logo} alt="logo" style={{ height: "50px" }} /> */}
          </NB.Brand>
          {/* Bu toggle butonu mobil cihazlarda gizlenecek */}
          <NB.Toggle
            aria-controls="responsive-navbar-nav"
            className="navbar-toggle"
          />
          <NB.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {!userInfo && !lawyerInfo && (
                <>
                  {is_lawyer ? (
                    <div className="d-flex">
                      <ButtonMainColor onClick={() => user()}>
                        Avukat arıyorum.
                      </ButtonMainColor>
                      <ButtonWhiteColor
                        onClick={() => setShowLawyerRegister(true)}
                      >
                        KAYIT OL
                      </ButtonWhiteColor>
                      <ButtonWhiteColor
                        onClick={() => setShowLawyerLogin(true)}
                      >
                        GİRİŞ YAP
                      </ButtonWhiteColor>
                    </div>
                  ) : (
                    <div className="navbarnavwrap d-flex align-items-center">
                      <div className="social-wrapper">
                        <a
                          href="https://www.linkedin.com/company/avukatlazim/"
                          rel="noreferrer"
                          target="_blank"
                          className="linkedin social"
                        >
                          <FontAwesomeIcon
                            className="sociali"
                            icon={faLinkedin}
                            size="2x"
                          />
                        </a>
                        <a
                          href="https://twitter.com"
                          rel="noreferrer"
                          target="_blank"
                          className="twitter social"
                        >
                          <FontAwesomeIcon
                            className="sociali"
                            icon={faXTwitter}
                            size="2x"
                          />
                        </a>
                        <a
                          href="https://instagram.com"
                          rel="noreferrer"
                          target="_blank"
                          className="instagram social"
                        >
                          <FontAwesomeIcon
                            className="sociali"
                            icon={faInstagram}
                            size="2x"
                          />
                        </a>
                      </div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="btnwrp d-flex align-items-center justify-content-center"
                          style={dropdownToggleStyle}
                        >
                          GİRİŞ YAP
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="dropdown-menulogin"
                          style={{ backgroundColor: "white", zIndex: 1050 }}
                        >
                          <Dropdown.Item
                            onClick={() => setShowUserLogin(true)}
                            style={{ color: "black" }}
                          >
                            Danışan Girişi
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setShowLawyerLogin(true)}
                            style={{ color: "black" }}
                          >
                            Avukat Girişi
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="btnwrp d-flex ms-3 align-items-center justify-content-center"
                          style={dropdownToggleStyle}
                        >
                          KAYIT OL
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ backgroundColor: "white", zIndex: 1050 }}
                        >
                          <Dropdown.Item
                            onClick={() => setShowUserRegister(true)}
                            style={{ color: "black" }}
                          >
                            Danışan Kayıt Ol
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setShowLawyerRegister(true)}
                            style={{ color: "black" }}
                          >
                            Avukat Kayıt Ol
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </>
              )}
              {userInfo && !lawyerInfo && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    className="bg-light shadow-5 text-dark m-2 border-0"
                  >
                    {userInfo?.name?.toUpperCase()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ zIndex: 1050 }}>
                    <Dropdown.Item onClick={() => navigate("/userdashboard")}>
                      Profilim
                    </Dropdown.Item>
                    <Dropdown.Item onClick={userSignOut}>
                      Çıkış Yap
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {lawyerInfo && !userInfo &&  (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    className="bg-light shadow-5 text-dark m-2 border-0"
                  >
                    {lawyerInfo?.name?.toUpperCase()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ zIndex: 1050 }}>
                    <Dropdown.Item
                      onClick={() =>
                        lawyerInfo?.isAdmin
                          ? navigate("/adminDashboard")
                          : navigate("/lawyerDashboard")
                      }
                    >
                      Profilim
                    </Dropdown.Item>
                    <Dropdown.Item onClick={lawyerSignOut}>
                      Çıkış Yap
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </NB.Collapse>
        </Container>
      </NB>
      <LawyerRegister
        setShowLawyerRegister={setShowLawyerRegister}
        setShowLawyerLogin={setShowLawyerLogin}
        show={showLawyerRegister}
        onHide={() => setShowLawyerRegister(false)}
      />
      <LawyerLogin
        setShowLawyerLogin={setShowLawyerLogin}
        setShowLawyerRegister={setShowLawyerRegister}
        show={showLawyerLogin}
        onHide={() => setShowLawyerLogin(false)}
      />
      <UserRegister
        setShowUserRegister={setShowUserRegister}
        setShowUserLogin={setShowUserLogin}
        show={showUserRegister}
        onHide={() => setShowUserRegister(false)}
      />
      <UserLogin
        setShowUserRegister={setShowUserRegister}
        setShowUserLogin={setShowUserLogin}
        show={showUserLogin}
        onHide={() => setShowUserLogin(false)}
      />
    </>
  );
};

export default Navbar;
