import React from "react";
import ServicesCard from "../services/ServicesCard";
import { RiInformation2Line } from "react-icons/ri";
import { RiUserLine } from "react-icons/ri";
import { BiBookmarkAltMinus } from "react-icons/bi";
import { PiNotePencilBold } from "react-icons/pi";
import { AiOutlineGlobal } from "react-icons/ai";
import "../../css/services.css";

const Services = () => {
  const servicesData = [
    {
      title: "İcra ve İflas Hukuku",
      description:
        "İcra Takiplerinin Başlatılmasının Ardından Yapılması Gerekenlerin Sağlanması",
      icon: RiInformation2Line,
    },
    {
      title: "Miras Hukuku",
      description:
        "Mirasın Reddi ve Mirastan Yoksun Bırakılma gibi Temel Dava Süreçleri",
      icon: RiUserLine,
    },
    {
      title: "İdare ve Vergi Hukuku",
      description: "Vergi Hukuku ve İdari Davalar Süreç Yönetimi",
      icon: BiBookmarkAltMinus,
    },
    {
      title: "Sözleşme Hukuku",
      description:
        "Özel veya Resmi Her Türlü Sözleşmenin Hazırlanması ve Süreç Takibi",
      icon: PiNotePencilBold,
    },
    // {
    //   title: "Uluslararası Hukuk",
    //   description:
    //     "Uluslararası Hukuk ile AİHM ve Avrupa Adalet Divanı’na Başvuru Süreçleri Takibi",
    //   icon: AiOutlineGlobal,
    // },
  ];

  return (
    <div className="services">
      <div className="servicetop">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="servicetitle">Hizmetlerimiz</h1>
          <div className="serviceicon d-flex gap-5">
            <i className="fa-solid fa-chevron-left"></i>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
        <hr className="hrservice" />
        <p className="servicesubtitle">
          Avukatlazım Pro üyelik ile internetin gücünden %100 faydalanın!
        </p>
      </div>

      <div className="serviceElements d-flex">
        {servicesData.map((service, index) => (
          <ServicesCard
            key={index}
            title={service.title}
            description={service.description}
            icon={service.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
