import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import RequestUpdate from "./RequestUpdate";
import { FaFileAlt } from "react-icons/fa"; // Font Awesome icon for the file
import FileListModal from "../AdminDashboard/components/FileListModal"; // Import the modal component

const AllRequestsFile = () => {
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const { state } = useContext(Store);
  const { lawyerInfo } = state;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/requests`, {
        headers: { Authorization: `Bearer ${lawyerInfo?.token}` },
      })
      .then((response) => {
        setRequests(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, [lawyerInfo?.token]);

  const [currentPage, setCurrentPage] = useState(1);

  const getDisplayedRequests = () => {
    const startIndex = (currentPage - 1) * 15;
    const endIndex = startIndex + 15;
    const filteredRequests = requests.filter(
      (request) => request.status === "accepted"
    );
    return filteredRequests.slice(startIndex, endIndex);
  };

  const handleClickUpdate = (request) => {
    if (!selectedRequest) {
      setSelectedRequest(request);
    } else {
      setSelectedRequest(null);
    }
  };

  const handleFileListClick = (files) => {
    setCurrentFiles(files || []);
    setIsModalOpen(true);
  };

  return (
    <div
      style={{
        padding: "10px",
        color: "black",
        borderRadius: "20px",
        border: "1px solid #7e8088",
      }}
    >
      <p style={{ font: "bold 20px/24px 'SF Pro Text', sans-serif" }}>
        Randevu Talepleri
      </p>
      <table
        className="table-request"
        style={{
          width: "100%",
          marginTop: "25px",
          borderCollapse: "separate",
          border: "1px solid #7e8088",
          borderRadius: "25px",
        }}
      >
        <thead
          style={{
            color: "#7e8088",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          <tr
            style={{
              font: "bold 16px/19px 'SF Pro Text', sans-serif",
              height: "25px",
            }}
          >
            <th
              style={{
                border: "1px solid #2d3657",
                width: "148px",
                height: "33px",
                padding: "7px",
                borderTopLeftRadius: "25px",
              }}
            >
              Avukat Adı
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "145px",
                padding: "7px",
              }}
            >
              Danışan adı
            </th>

            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                padding: "7px",
              }}
            >
              Tarih
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                padding: "7px",
              }}
            >
              Branş
            </th>
            <th
              style={{
                border: "1px solid",
                width: "160px",
                padding: "7px",
                borderTopRightRadius: "25px",
              }}
            >
              Durum
            </th>
          </tr>
        </thead>
        <tbody style={{ font: "normal 14px/16px 'SF Pro Text', sans-serif" }}>
          {getDisplayedRequests().map((request, index) => (
            <tr
              key={request?._id}
              // onClick={() => handleClickUpdate(request)}
              style={{
                borderBottom: "1px solid #2d3657",
                padding: "10px 0",
                backgroundColor:
                  selectedRequest === index ? "#2d3657" : "transparent",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              <td style={{ padding: "7px" }}>
                {request?.lawyerId
                  ? `${request?.lawyerId.name} ${request?.lawyerId.surname}`
                  : "----------"}
              </td>
              <td style={{ padding: "7px" }}>
                {request?.userId.name} {request?.userId.surname}
              </td>

              <td style={{ padding: "7px" }}>
                {new Date(request?.startDate).toLocaleDateString("tr-TR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}{" "}
                -
                {new Date(request?.endDate).toLocaleDateString("tr-TR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td style={{ padding: "7px" }}>{request?.branchId?.title}</td>
              <td
                style={{
                  padding: "2px",
                  margin: "5px 0",
                  backgroundColor: getStatusColor(request?.status),
                  color: getStatusTextColor(request?.status),
                  borderRadius: "25px",
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "150px",
                  height: "33px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getStatusText(request?.status)}
                {request?.status === "accepted" && (
                  <FaFileAlt
                    className="file-icon"
                    style={{ marginLeft: "15px", fontSize: "18px" }}
                    onClick={() => handleFileListClick(request.files || [])}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRequest && (
        <RequestUpdate
          request={selectedRequest}
          setSelectedRow={setSelectedRequest}
          lawyerInfo={lawyerInfo}
        />
      )}
      <div
        style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
      >
        {Array(
          Math.ceil(
            requests.filter((request) => request.status === "accepted").length /
              15
          )
        )
          .fill(0)
          .map((_, i) => (
            <button
              key={i}
              style={{
                border: currentPage === i + 1 ? "none" : "1px solid #2d3657",
                backgroundColor:
                  currentPage === i + 1 ? "#2d3657" : "transparent",
                borderRadius: "14px",
                font: "normal 16px 'SF Pro Text', sans-serif",
                padding: "2px 6px",
                color: currentPage === i + 1 ? "#FFFFFF" : "#ffff",
                marginRight: "5px",
              }}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
      </div>
      {/* Render the FileListModal component */}
      <FileListModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        files={currentFiles}
      />
    </div>
  );
};

// Utility functions for status color and text color
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#F8C106";
    case "accepted":
      return "#10986F";
    case "canceled":
      return "#F64E60";
    case "completed":
      return "#3699FF";
    default:
      return "transparent";
  }
};

const getStatusTextColor = (status) => {
  return "#FFFFFF"; // White color for all statuses
};

const getStatusText = (status) => {
  switch (status) {
    case "pending":
      return "Bekliyor";
    case "accepted":
      return "Kabul Edildi";
    case "canceled":
      return "İptal Edildi";
    case "completed":
      return "Tamamlandı";
    default:
      return "";
  }
};

export default AllRequestsFile;
