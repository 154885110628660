import React, { useContext, useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaUser,
  FaHeart,
  FaLock,
  FaChevronDown,
  FaChevronUp,
  FaWallet,
  FaQuestionCircle,
  FaFile,
} from "react-icons/fa";
import photo from "../assets/photo.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UserRandevularım from "../components/UserDashboard/UserRandevularım";
import UserKişiselBilgiler from "../components/UserDashboard/UserPersonalDetail";
import FavouriteLawyers from "../components/UserDashboard/FavouriteLawyers";
import { Store } from "../Store";
import NavbarMenu from "../components/NavbarMenu";
import UserPastPay from "../components/UserDashboard/UserPastPay";
import image from "../assets/avatar.jpg";
import axios from "axios";
import { getError } from "../getError";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import LoadingBox from "../components/LoadingBox";
import UserPasswordChange from "../components/UserDashboard/UserPasswordChange";

const items = [
  { name: "Randevularım", icon: <FaCalendarAlt /> },
  { name: "Kişisel Bilgiler", icon: <FaUser /> },
  { name: "Favori Avukatlarım", icon: <FaHeart /> },
  { name: "Sifre Islemleri", icon: <FaLock /> },
  { name: "Dosyalarım", icon: <FaFile /> },
];

const UserDashboard = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [selectedItem, setSelectedItem] = useState(null);
  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [picture, setPicture] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const showComponent = (item) => {
    if (item === "Randevularım") {
      return <UserRandevularım handleDateClick={handleDateClick} />;
    } else if (item === "Kişisel Bilgiler") {
      return <UserKişiselBilgiler />;
    } else if (item === "Favori Avukatlarım") {
      return <FavouriteLawyers />;
    } else if (item === "Dosyalarım") {
      return "";
    } else if (item === "Geçmiş Ödemelerim") {
      return <UserPastPay />;
    } else if (item === "Sifre Islemleri") {
      return <UserPasswordChange />;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (userInfo?.picture) {
      setPicture(userInfo.picture);
    }
  }, [userInfo.picture]);

  const onItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleDateClick = (date, index) => {
    setSelectedRow(index);
    setSelectedDate(date);
  };

  const togglePayments = () => {
    setPaymentsOpen(!paymentsOpen);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("picture", file);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/upload/image`,
        bodyFormData,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setPicture(data.filename);
      toast.success("Resim yüklendi. Güncellemek için kaydediniz.");
    } catch (err) {
      toast.error(getError(err));
    }
  };
  const savePicture = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/users/${userInfo._id}`,
        {
          picture,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setLoading(false);
      toast.success("Resminiz Kaydedildi.");
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <NavbarMenu />
      <div
        className="userdashmain d-flex border border-warning m-auto lawyerdashbord-container mt-5 mb-5 rounded-3"
        style={{ height: "fit-content", width: "1034px" }}
      >
        <div className="lawyerdashbord-leftcard">
          <div className="d-flex justify-content-center ">
            <div className="lawyerdashboard-photo">
              <img
                width="100px"
                height="90px"
                alt=""
                src={
                  userInfo.picture
                    ? `${process.env.REACT_APP_BASE_URI}/uploads/image/${userInfo.picture}`
                    : image
                }
              />
            </div>
          </div>
          <Form.Group className="mb-5 ms-5 fs-5" controlId="imageFile">
            <Form.Control type="file" onChange={uploadFileHandler} />
          </Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <button
              className="lawyerdashboard-button-kaydet "
              onClick={savePicture}
            >
              {loading ? (
                <>
                  <LoadingBox />
                </>
              ) : (
                "Kaydet"
              )}
            </button>
          </div>
          <div className="d-flex justify-content-center m-2">
            {userInfo?.name} {userInfo?.surname}
          </div>
          <div className="border-bottom"></div>
          <div className="m-5 hesabım">
            <h1>
              <b>Hesabım</b>
            </h1>
            <ul>
              {items.map((item, index) => (
                <li
                  key={index}
                  onClick={() => onItemClick(item.name)}
                  style={{
                    fontWeight: selectedItem === item.name ? "bold" : "normal",
                    listStyle: "none",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    gap: "5px",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor:
                      selectedItem === item.name
                        ? "var(--main-color)"
                        : "transparent",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      selectedItem === item.name ? "#d0e7ff" : "transparent")
                  }
                >
                  {item.icon}
                  {item.name}
                </li>
              ))}
              <li
                style={{
                  listStyle: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "5px",
                  borderRadius: "5px",
                }}
                onClick={togglePayments}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = paymentsOpen
                    ? "#d0e7ff"
                    : "transparent")
                }
              >
                <FaWallet />
                Ödemelerim{" "}
                {paymentsOpen ? (
                  <FaChevronUp
                    style={{
                      fontSize: "13px",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  />
                ) : (
                  <FaChevronDown
                    style={{
                      fontSize: "13px",
                      marginLeft: "5px",
                      marginTop: "3px",
                    }}
                  />
                )}
              </li>
              {paymentsOpen && (
                <ul style={{ paddingLeft: "20px", listStyle: "none" }}>
                  <li
                    onClick={() => onItemClick("Geçmiş Ödemelerim")}
                    style={{
                      fontWeight:
                        selectedItem === "Geçmiş Ödemelerim"
                          ? "bold"
                          : "normal",
                      cursor: "pointer",
                      padding: "10px",
                      borderRadius: "5px",
                      backgroundColor:
                        selectedItem === "Geçmiş Ödemelerim"
                          ? "#d0e7ff"
                          : "transparent",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f0f0f0")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        selectedItem === "Geçmiş Ödemelerim"
                          ? "#d0e7ff"
                          : "transparent")
                    }
                  >
                    Geçmiş Ödemelerim
                  </li>
                  <li
                    onClick={() => onItemClick("Ödeme Yap")}
                    style={{
                      fontWeight:
                        selectedItem === "Ödeme Yap" ? "bold" : "normal",
                      cursor: "pointer",
                      padding: "10px",
                      borderRadius: "5px",
                      backgroundColor:
                        selectedItem === "Ödeme Yap"
                          ? "#d0e7ff"
                          : "transparent",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f0f0f0")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        selectedItem === "Ödeme Yap"
                          ? "#d0e7ff"
                          : "transparent")
                    }
                  >
                    Ödeme Yap
                  </li>
                </ul>
              )}
              <li
                style={{
                  listStyle: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "5px",
                  borderRadius: "5px",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <FaQuestionCircle />
                <a style={{ color: "black", textDecoration: "none" }} href="#">
                  Yardım ve Sorular
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="search-left-border"></div>
        <div className="lawyerdashbord-rightcard">
          {showComponent(selectedItem)}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserDashboard;
