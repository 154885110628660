// FileListModal.js
import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Adjust this if needed

const FileListModal = ({ isOpen, onRequestClose, files }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File List"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
        },
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2>Dosyalar</h2>
        <button onClick={onRequestClose} style={{ marginBottom: "10px" }}>
          Kapat
        </button>
      </div>
      {files.length === 0 ? (
        <p>Henüz dosya eklenmemiş.</p>
      ) : (
        <ul>
          {files.map((file, index) => (
            <li key={index}>
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

export default FileListModal;
