import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../getError";

const UserRandevularım = ({ handleDateClick, index }) => {
  const [userRequest, setUserRequest] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/users/${userInfo?._id}`, {
        headers: { Authorization: `Bearer ${userInfo?.token}` },
      })
      .then((response) => {
        setUserRequest(response.data.requests);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const getDisplayedDates = () => {
    const startIndex = (currentPage - 1) * 15;
    const endIndex = startIndex + 15;
    return userRequest.slice(startIndex, endIndex);
  };
  return (
    <div style={{ padding: "10px", color: " #00242E" }}>
      <p
        style={{
          font: "normal normal bold 20px/24px SF Pro Text",
        }}
      >
        Randevularım
      </p>
      <table
        className="tablerandevular"
        style={{ width: "100%", marginTop: "25px" }}
      >
        <thead>
          <tr
            style={{
              font: " normal normal bold 16px/19px SF Pro Text",
              height: "25px",
            }}
          >
            <th
              style={{
                border: "1px solid #2d3657",
                width: "148px",
                height: "33px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Avukat Adı
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "145px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Konu{" "}
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Tarih
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "86px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Yer
            </th>
            {/* <th
              style={{
                border: "1px solid #2d3657",
                width: "145px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Referans No
            </th> */}
            <th
              style={{
                border: "1px solid #2d3657",
                width: "131px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Durum
            </th>
          </tr>
        </thead>
        <tbody style={{ font: "normal small normal 14px/16px SF Pro Text" }}>
          {getDisplayedDates().map((request, index) => (
            <tr
              key={request._id}
              onClick={() => handleDateClick(request, index)}
              style={{
                borderBottom: "1px solid #2d3657",
                paddingTop: "10px",
                paddingBottom: "10px",
                font: "normal normal normal 14px/16px SF Pro Text",
                backgroundColor:
                  selectedRow === index ? "#2d3657" : "transparent",
              }}
            >
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request?.lawyerId?.name || "Avukat Atanmadı"}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request.description}
              </td>
              <td
                className="tablerandevular-tarih"
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {new Date(request.startDate).toLocaleDateString()} /{" "}
                {new Date(request.endDate).toLocaleDateString()}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request?.locationId.country}
              </td>
              {/* <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request._id}
              </td> */}
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                <button
                  style={{
                    background: getStatusColor(request.status),
                    color: getStatusTextColor(request.status),
                    borderRadius: "25px",
                    font: "normal normal normal 14px/16px SF Pro Text",
                    padding: "4px 12px",
                    marginLeft: "5px",
                  }}
                >
                  {request.status === "pending"
                    ? "Bekliyor"
                    : request.status === "completed"
                    ? "Tamamlandı"
                    : request.status === "canceled"
                    ? "İptal Edildi"
                    : request.status === "accepted"
                    ? "Kabul Edildi"
                    : "Durum Ayarlanmadı"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
      >
        {Array(Math.ceil(userRequest.length / 15))
          .fill(0)
          .map((_, i) => (
            <button
              key={i}
              style={{
                border: currentPage === i + 1 ? "none" : "1px solid #00242E",
                background: currentPage === i + 1 ? "#2d3657" : "",
                borderRadius: "14px",
                font: "normal normal normal 16px SF Pro Text",
                padding: "2px 6px",
                color: currentPage === i + 1 ? "#FFFFFF" : "#00242e",
                marginRight: "5px",
              }}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
      </div>
    </div>
  );
};
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#F8C106";
    case "accepted":
      return "#10986F";
    case "canceled":
      return "#F64E60";
    case "completed":
      return "#3699FF";
    default:
      return "transparent";
  }
};
const getStatusTextColor = (status) => {
  return "#FFFFFF"; // White color for all statuses
};
export default UserRandevularım;
